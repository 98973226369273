.landingPage {
    background: var(--bodyColor);
    min-height: 100vh;
}

.sideLink {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: 12px 20px;
    border-radius: 60px;
    cursor: pointer;
    margin-bottom: 8px;
}

.sideLink:hover {
    background: var(--containerColor);
}

.active p {
    color: var(--colorPrimary) !important;
    font-size: 16px !important;
    font-weight: 600 !important;
}

.active>div {
    background-color: var(--containerColor);
}

.active>div>h5 {
    background-color: var(--containerColor);
    color: var(--colorPrimary)
}

.sideLink:hover h5 {
    color: var(--sideActiveColor) !important
}

.sideLink i {
    display: flex;
    margin-right: 10px;
    color: var(--colorPrimary);
    font-size: 24px;
}

.sideLink h5 {
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--textHeading);
}

.sidebar {
    width: 200px;
    margin-right: 20px;
    -webkit-user-select: none;
    user-select: none;
}

.landingPageContent {
    display: flex;
    display: 100%
}

.dashboardPages {
    width: calc(100% - 220px);
}

.sidebarlogoDiv img {
    width: 170px;
    margin-top: 16px;
    filter: drop-shadow(2px 6px 6px black);
}

#sideItems {
    margin-top: 50px;
}

.sidebarlogoDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sidebarlogoDiv i {
    font-size: 30px;
    color: var(--textHeading);
    display: none;
}

.headerLinkDash {
    display: none;
    width: 350px;
    margin: auto;
}

a {
    text-decoration: none !important;
}

#partnershipSideDiv {
    display: flex;
    align-items: center;
}

#partnershipSideDiv i {
    margin-left: 5px;
    display: flex;
    align-items: center;
    color: var(--colorPrimary);
    transition: all 0.3s;
}

#sideDropdown {
    margin-left: 52px;
    transition: height 0.5s;
}

#sideDropdown p {
    font-style: normal;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    margin-bottom: 15px;
    color: var(--textColor);
    transition: all 0.3s;
}

#sideDropdown p:hover {
    color: var(--colorPrimary);
}

#topNotification {
    padding: 0px 30px 30px 30px;
    border-left: 1px solid #353535;
}

@media (max-width: 800px) {
    .sidebar {
        overflow-y: scroll;
        position: fixed;
        margin-right: 30px;
        width: 100%;
        background: var(--containerColor);
        height: 100%;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        padding: 30px;
        z-index: 99999999;
        transition: all 0.4s;
    }

    .sidebarlogoDiv i {
        display: flex;
    }

    .headerLinkDash {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
    }

    #topNotification {
        padding: 0px 0px 30px 0px;
        border-left: 1px solid #353535;
    }
}

.addfundDivFundWallet {
    display: flex;
    justify-content: space-between;
    color: var(--textColor);
}

.otpSection {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    position: fixed;
    background: rgba(0, 0, 0, 0.884);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
    padding: 20px;
}

.otpContainer {
    width: 300px;
    background: var(--containerColor);
    padding: 30px 20px;
    border-radius: 20px;
    text-align: center;
    box-shadow: 0px 0px 3px 0px var(--textColor);
}

.otpContainer h1 {
    color: var(--colorPrimary);
    font-size: 30px;
}

.otpContainer p {
    color: var(--textColor);
    font-size: 15px;
}

.otpContainer input {
    width: 100%;
    padding: 10px;
    border-radius: 25px;
    background: var(--lightColor);
    border: 1px solid var(--textColor);

    color: var(--textHeading);
    outline: none;
    text-align: center;
}

.otpContainer textarea {
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    background: var(--lightColor);
    border: 1px solid var(--textColor);
    color: var(--textHeading);
    outline: none;
    text-align: start;
    font-size: 13px;
    font-weight: 300;
    letter-spacing: 1px;
    resize: none;
}

.otpContainer div {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.otpLoading {
    border: 2px solid var(--colorPrimary);
    border-top-color: var(--containerColor);
    border-left-color: var(--containerColor);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin: auto;
    animation: rotate 0.4s linear infinite;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media (max-width: 1000px) {
    .headerLinkDash {
        display: block;
        width: 100%;
    }

    #topNotification {
        border: none;
    }
}

@media (max-width: 800px) {
    .dashboardPages {
        width: 100%;
    }
}