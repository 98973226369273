.googleAuth {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.googleAuthDiv {
    width: 100%;
    max-width: 400px;
    text-align: center;
    background: var(--containerColor);
    padding: 20px;
    border-radius: 10px;
    top: 10px;
}

.googleAuthDiv h1 {
    color: var(--textHeading);
    font-size: 20px;
    margin-bottom: 20px;
}

.googleAuthDiv h5 {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    color: var(--textColor);
}

.googleAuth p {
    font-size: 12px;
    color: var(--textHeading);
    font-weight: 500
}

.googleAuth span {
    font-weight: 300 !important;
    color: var(--textColor) !important;
}

.googleAuthDiv label {
    text-align: left;
    width: 100%;
}

#authQR {
    width: 150px;
    margin-bottom: 6px;
}

#authLogo {
    width: 70px;
    margin-bottom: 40px;
}

/* mohit css */

.googleEnable {
    margin: auto;
    text-align: center;
    width: 100%;
    max-width: 600px;
}

img#google_id {
    width: 170px;
    display: block;
    margin: auto;
}

.googleEnable h4 {
    text-align: center;
    margin: 15px 0px 0px 0px;
    /* color: #4BAE4F; */
    color: var(--colorPrimary);
    font-size: 14px;
    font-weight: normal;
}

.googleEnable h5 {
    text-align: center;
    margin: 15px 0px 0px 0px;
    color: var(--textHeading);
    font-size: 20px;
    margin-bottom: 20px;
}

.googleEnable p {
    font-size: 14px;
    color: var(--textColor);
    margin-top: 40px;
}

.googleEnable input {
    border-radius: 30px;
    border: none;
    padding: 5px 20px;
    outline: none;
    background: var(--containerColor);
    color: var(--textHeading);
    display: block;
    margin: auto;
    border: 1px solid grey;
    text-align: center;
}

.googleEnable button {
    margin-top: 10px;
    border: none;
    border-radius: 20px;
    font-size: 12px;
    padding: 5px 20px;
    background: var(--btnBackground);
    font-weight: bold;
}