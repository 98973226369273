.withdrawSelectWallet {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    margin-bottom: 10px;
    margin-top: 5px;
}

.withdrawSelectWallet div {
    border: 2px none var(--textHeading);
    padding: 20px 10px;
    border-radius: 10px;
    text-align: center;
    flex: 1;
    cursor: pointer;
    background-color: rgb(78, 76, 76);
}

.withdrawSelectWallet div h5 {
    color: var(--textHeading);
    margin: 0;
    font-size: 25px;
    line-height: 20px;
    font-weight: 700;
}

.withdrawSelectWallet div p {
    color: var(--textHeading);
    font-size: 20px;
    /* margin-top: 20px; */
    font-weight: 500
}

.withdrawSelectWalletActive {
    border: 1px solid var(--colorPrimary) !important;
    background: var(--colorPrimary);
}

.withdrawSelectWalletActive h5,
.withdrawSelectWalletActive p {
    color: black !important;
    font-weight: 500 !important;
}

#withdrawAddress {
    font-size: 12px;
    margin: 0;
    color: var(--textColor);
    word-break: break-all;
    font-weight: 300;
}

#withdrawAddressError p {
    font-size: 12px;
    color: rgb(245, 52, 52);
    font-weight: 300;
    display: flex;
    align-items: center;
    margin: 0;
}

#withdrawAddressError button {
    color: black;
    background: var(--colorPrimary);
    font-size: 10px;
    font-weight: 500;
    border-radius: 30px;
    padding: 2px 5px;
    border: 1px solid transparent;
}