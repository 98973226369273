.header {
    padding: 16px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 34px;
}

.headerLogoLink {
    display: flex;
    align-items: center;
}

.headerLinkDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--containerColor);
    padding: 5px 20px;
    border-radius: 20px;
    cursor: pointer;
}

.headerLinkDiv p {
    margin: 0;
    font-size: 9px;
    color: var(--textColor);
    text-transform: uppercase;
}

.headerLinkDiv i {
    font-size: 18px;
    margin-left: 35px;
    color: var(--textHeading);
    display: flex;

}

.headerLinkDiv h2 {
    margin: 0;
    color: var(--colorPrimary);
    font-size: 14px;
    font-weight: 400;
}

.headerProfileColorDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.themeChangeIcons {
    display: flex;
    align-items: center;
    justify-content: center;
}

.themeChangeIcons p {
    margin: 0;
    font-size: 8px;
    font-weight: 400;
    text-transform: uppercase;
    line-height: 10px;
    color: var(--textColor);
    margin-right: 10px;
    cursor: default;
}

.themeChangeIcons i {
    font-size: 22px;
    color: orange;
    margin-right: 10px;
    cursor: pointer;

}

#moon {
    font-size: 20px;
    color: var(--colorPrimary);
}

.headerProfile h5 {
    font-size: 15px;
    margin: 0;
    color: var(--textColor);
    margin: 0px 5px;
    margin-left: 10px;
    font-weight: 400;
}

.headerProfile {
    display: flex;
    background: var(--containerColor);
    align-items: center;
    padding: 6px 10px;
    border-radius: 20px;
    cursor: pointer;
}

.headerProfile img {
    width: 30px;
    border-radius: 50%;
}

.headerProfile i {
    display: flex;
    margin: 0px 4px;
    font-size: 22px;
    color: var(--textColor);
}

.settingDiv {
    position: absolute;
    top: 45px;
    background: var(--containerColor);
    padding: 20px 27px 13px 20px;
    border-radius: 7px !important;
    right: 0px;
    border: 1px solid #80808033;
}

.settingDivItem {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.settingDivItem p {
    margin: 0;
    font-size: 12px;
    color: var(--textColor);
}

.settingDivItem i {
    color: var(--textColor);
}

.alertMsg {
    background: var(--containerColor);
    padding: 20px 120px;
    border-radius: 50px;
    color: var(--colorPrimary);
    text-align: center;
    position: fixed;
    left: 50%;
    box-shadow: 0px 0px 3px 0px var(--colorPrimary);
    transform: translateX(-50%);
    transition: top 0.5s;
    white-space: nowrap;
    z-index: 9;
}

.menuIcon,
.userIcon {
    font-size: 25px;
    color: var(--colorPrimary);
    display: flex;
    align-items: center;
    display: none;
}

#logoicon {
    width: 40px;
    display: none;
}

.menuIcon {
    font-size: 30px;
}

.mobileStore {
    display: flex;
    gap: 10px;
    margin-right: 10px;
}

.mobileStore img {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

@media (max-width: 800px) {

    .menuIcon,
    .userIcon {
        display: flex;
    }

    .headerProfile {
        display: none;
    }

    #logoicon {
        display: block;
    }

    .header {
        padding: 16px 0;
    }
}


@media (max-width: 1000px) {
    .headerLinkHeader {
        display: none;
    }
}









/* ---------------------------------------------- */
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

.checkbox {
    opacity: 0;
    position: absolute;
}

.checkbox-label {
    background-color: rgb(56, 56, 56);
    width: 50px;
    height: 26px;
    border-radius: 50px;
    position: relative;
    padding: 5px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.fa-moon {
    color: #f1c40f;
}

.fa-sun {
    color: #f39c12;
}

.checkbox-label .ball {
    background-color: #fff;
    width: 22px;
    height: 22px;
    position: absolute;
    left: 2px;
    top: 2px;
    border-radius: 50%;
    transition: transform 0.2s linear;
}

.checkbox:checked+.checkbox-label .ball {
    transform: translateX(24px);
}









/*  Support me if you like it */
.support {
    position: absolute;
    right: 20px;
    bottom: 20px;
}

.support a {
    color: #292c35;
    font-size: 32px;
    backface-visibility: hidden;
    display: inline-block;
    transition: transform 0.2s ease;
}

.support a:hover {
    transform: scale(1.1);
}