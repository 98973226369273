.rewardCard {
    padding: 10px;
    border-radius: 20px;
    background: var(--containerColor);
    text-align: center;
    height: 100%;
}

.rewardCard img {
    width: 40%;
    margin: 20px 0;
}

.rewardCardDiv h5 {
    font-size: 20px;
    color: var(--textHeading);
    margin: 0;
}

.rewardCardDiv h4 {
    font-size: 14px;
    color: var(--textHeading);
    margin: 0;
    font-weight: 300;
}

.rewardCardDiv p {
    font-size: 12px;
    color: var(--textColor);
}

.rewardCard h1 {
    font-size: 20px;
    margin-top: 10px;
    font-weight: 700;
    color: var(--colorPrimary);
}

.rewardCard p {
    color: var(--textColor);
    font-weight: 300;
}

#rewardCardActive {
    box-shadow: 0px 0px 3px 0px grey;
    background: black
}